import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OnasPage = () => (
  <Layout>
    <SEO title="O kancelarii - Kancelaria Prawna RIO" />
    <section className="top content-page">
      <div className="wrap">
        <h2>O kancelarii</h2>
      </div>
    </section>

    <section className="team">
      <div className="wrap">
        <blockquote>
          Zagadnienia prawne stanowią nie tylko wykonywany przez nas zawód ale
          są też naszą pasją w ramach której staramy się dążyć do doskonałości.
        </blockquote>
        <div>
          <h2>Zespół</h2>
          <div>
            <h3>Radca Prawny Anna Górska</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Consequatur ullam sapiente animi necessitatibus eaque sint
              possimus numquam nulla adipisci recusandae? Placeat pariatur qui
              minima repellat ea odio, molestiae quis ab.
            </p>
          </div>
          <div>
            <h3>Aplikant Radcowski Yuliya Kaczor</h3>
            <p>
              Magister filologii ukraińskiej oraz polskiej (2000-2005), magister
              prawa w Wyższej Szkole Bankowej w Gdańsku (2012-2017), aplikant
              radcowski II roku, tłumacz oraz lektor języka ukraińskiego,
              rosyjskiego oraz polskiego z czternastoletnim doświadczeniem (od 9
              lat współpracuje z Morskim Oddziałem Straży Granicznej w Gdyni).
              Zainteresowania: prawo, języki obce, psychologia, Power Pump.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default OnasPage
